<template>
  <div class="me">
    <Header :active=5 :full='fullWidth'/>
    <!-- 轮播图 -->
    <el-image style="width: 100%; margin-top:74px" :src="imgs[0].url" v-if="fullWidth"></el-image>
    <el-image style="width: 100%; margin-top:52px" :src="imgs[1].url"  v-if="!fullWidth"></el-image>
    <!-- <el-carousel  :height="fullWidth == true ? '400px' : '150px'">
      <el-carousel-item v-for="item in imgs" :key="item.id" >
        <el-image :src="item.url" style="width: 100%;height:100%;"></el-image>
      </el-carousel-item>
    </el-carousel> -->
    <!-- PC端 -->
    <!-- 火眼防伪 -->
    <div class="me_center" v-show="fullWidth">
      <div class="center_top">
        <span class="me_span">About Us</span>
        <span class="span_centet">关于火眼防伪</span>
        <span class="span_botton"></span>
      </div>
       <div class="center_bottom">
        <div class="bottom_left"></div>
        <div class="bottom_right">
          <span style="font-size:14px;line-height: 48px;">
            烟台火眼防伪科技有限责任公司成立于2018年， 总部设在中国烟台，是山东省内首家防伪科技公司， 专注于新一代人工智能和区块链防伪追溯技术应用（主营）， 致力于品牌及消费者合法权益保护。 火眼防伪科技旗下的【火眼防伪实验室】， 是由互联网和区块链技术专家、济南大学人工智能研究院的人工智能学者教授以及包装印刷工艺专家团队组成， 专注于人工智能和区块链防伪追溯技术领域的前沿探索。
          </span>
        </div>
      </div>
      <!-- 我们的愿景 -->
      <div class="me_bottom">
          <div class="vision">我们的愿景</div>
          <div class="me_text">专注于人工智能和区块链防伪追溯技术领域的前沿探索，致力于品牌及消费者合法权益保护</div>
      </div>
    </div>



    <!-- 移动端样式 -->
    <div class="move_center" v-show="!fullWidth">
      <div class="movecenter_top">
        <span class="moveme_span">About Us</span>
        <span class="movespan_centet">关于火眼防伪</span>
        <span class="movespan_botton"></span>
      </div>
      <div class="move_bottom">
        <div class="movebottom_left" >
          <!-- <img class="move_img" src="../assets/img/bg-2.jpg" alt="">
          <div class="move_float"></div> -->
        </div>
        <div class="move_right">
          <span>
            烟台火眼防伪科技有限责任公司成立于2018年， 总部设在中国烟台，是山东省内首家防伪科技公司， 专注于新一代人工智能和区块链防伪追溯技术应用（主营）， 致力于品牌及消费者合法权益保护。 火眼防伪科技旗下的【火眼防伪实验室】， 是由互联网和区块链技术专家、济南大学人工智能研究院的人工智能学者教授以及包装印刷工艺专家团队组成， 专注于人工智能和区块链防伪追溯技术领域的前沿探索。
          </span>
        </div>

      </div>
      <!-- 我们的愿景 -->
      <div class="moveme_bottom">
        <div class="move_vision">我们的愿景</div>
        <div class="move_text">专注于人工智能和区块链防伪追溯技术领域的前沿探索，致力于品牌及消费者合法权益保护</div>
      </div>
    </div>
    <Footer :full='fullWidth'/>
  </div>

</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import Footer from '@/components/Footer.vue'
export default {
  name: "Home",
  components: {
      Header,
      Footer
    },

  data(){
    return{
      // 轮播图
      imgs:[
        {id:1,url:require('../assets/innovative/me.png')},
        {id:1,url:require('../assets/innovative/move_me.png')},
      ],
      fullWidth:true,
    }
  },
  created(){

  },
  methods:{
      page_width() {//获取屏幕宽度
      let screenWidth = window.screen.width;
      console.log(screenWidth);
      if (screenWidth < 800) {
        this.fullWidth = false;
      } else {
        this.fullWidth = true;
      }

    },
    setRem() {
      var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
      var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
      var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
    }
  },
  mounted(){
    window.onresize = () => {//监听屏幕变化
      this.page_width();
      this.setRem()
    };
    this.page_width();
    this.setRem()
  },

};
</script>
<style scoped>
.me{

}

/* PC端样式 */
/* 头 */
.me_center{margin-top: 60px;display: flex;flex-direction:column;}
.center_top{margin: 0 auto;display: flex;flex-direction:column;align-items: center;}
.me_span{/* width: 377px; */ height: 25px;font-size: 18px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 25px;}
.span_centet{height: 25px;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #474747;line-height: 25px;margin: 11px 0 10px 0;}
.span_botton{width: 79px;height: 8px;background: #DDDDDD;border-radius: 4px;}

/* 火眼防伪 */
.center_bottom{display: flex;width: 1200px; justify-content: center; margin: 0 auto;height: 364px;margin-top: 31px;}
.bottom_left{position: relative;width: 40%;height: 323px;   background-image:url(../assets/img/me/me.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.left_img{margin-left: 40%;position: absolute;z-index: 999;}
.left_float{width: 40%;height: 212px;background: #E02222;position: absolute;bottom: 0;}
.bottom_right{width: 472px;height: 330px;font-size: 18px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #252525;line-height: 33px;margin-left: 90px;}

/* 我们的愿景 */
.me_bottom{height: 564px;background: #000000;margin-top: 72px;display: flex;justify-content: center;align-items: center;flex-direction:column;background-image:url(../assets/img/me1.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.vision{margin-bottom: 22px; width: 139px;height: 38px;border: 1px solid #FFFFFF;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 38px;text-align: center;}
.me_text{width: 551px;height: 60px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 30px;letter-spacing: 1px;text-align: center;}

/* 移动端样式 */
/* 头 */
.move_center{margin-top: 20px;display: flex;flex-direction:column;}
.movecenter_top{margin: 0 auto;display: flex;flex-direction:column;align-items: center;}
.moveme_span{height: 17px;font-size: 12px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 17px;}
.movespan_centet{margin-top: 5px;height: 17px;font-size: 12px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #474747;line-height: 17px;}
.movespan_botton{width: 40px;height: 4px;background: #DDDDDD;border-radius: 4px;margin-top: 5px;}

/* 火眼防伪 */
.move_bottom{display: flex;margin-top: 22px;}
.movebottom_left{position: relative;width: 192px;height: 136px; background-image:url(../assets/img/me/movegongsi.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.move_img{width: 177px;height: 136px;position: absolute;z-index: 999;margin-left: 15px;}
.move_float{width: 159px;height: 89px;background: #E02222;position: absolute;bottom: 0;}
.move_right{width: 160px;height: 130px;font-size: 10px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #252525;line-height: 12px;margin-left: 28px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 11;overflow: hidden;}

/* 我们的愿景 */
.moveme_bottom{height: 147px;background: #000000;margin-top: 24px;display: flex;justify-content: center;align-items: center;flex-direction:column;background-image:url(../assets/img/me1.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.move_vision{width: 77px;height: 21px;border: 1px solid #FFFFFF;font-size: 12px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 21px;text-align: center;}
.move_text{margin-top: 11px; width: 267px;height: 26px;font-size: 7px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 13px;letter-spacing: 1px;text-align: center;}
</style>
